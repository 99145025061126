import ApiService from './_private/ApiService';

export default class CommitService {
  apiService: ApiService;

  constructor(host: string, apiRoute: string) {
    this.apiService = new ApiService(host, apiRoute);
  }

  setToken(token: string) {
    this.apiService.setToken(token);
  }

  async getCommits(
    projectId: string,
    userId: string,
    deviceId?: string | '',
  ) {
    const commits = await this.apiService.getCommits(
      projectId,
      userId,
 //     deviceId,
    );

    return commits;
  }

  async createCommit(
    data: { message: string; files: { id: string; content: string }[] },
    projectId: string,
    userId: string,
    deviceId?: string | '',
  ) {
    const commit = await this.apiService.createCommit(
      data,
      projectId,
      userId,
      deviceId,
    );
    return commit;
  }
}
