import Project, { InfoPartOfProject, Template } from '@projectTypes/Project';
import ApiService from './_private/ApiService';
import TemplateApiService from './_private/TemplateApiService';
import fileService from '../FileService';
export default class ProjectService {
  apiService: ApiService;
  templateApiService: TemplateApiService;
  projects: Project[];
  templates: Template[];

  constructor(runHost: string, host: string, apiRoute: string) {
    this.apiService = new ApiService(runHost, host, apiRoute);
    this.templateApiService = new TemplateApiService(host, apiRoute);
    this.projects = [];
    this.templates = [];
  }

  async getProject(id: string, withCredentials?: boolean) {
    const project = await this.apiService.getProject(id, withCredentials);
    if (project === null) {
      return null;
    }
    this.projects.push(project);
    return project;
  }

  async getUserProjects() {
    const projects = await this.apiService.getUserProjects();
    if (projects === null) {
      return this.projects;
    }
    this.projects.push(...projects);
    return projects;
  }

  async getProjectByTemplate({
    templateSlug,
  }: {
    templateSlug: string;
  }): Promise<Project | null> {
    if (this.templates.length === 0) {
      await this.getProjectTemplates();
    }
    const template = this.templates.find(
      (template) => template.slug === templateSlug,
    );
    if (template) {
      return this.apiService.getProjectByTemplate(template.id);
    }

    return null;
  }

  async createProject(name: string, username: string) {
    const project = await this.apiService.createProject(name, username);
    if (project === null) {
      return null;
    }
    this.projects.push(project);
    return project;
  }

  async updateProject(projectId: string, partialProject: Partial<Project>) {
    const project = await this.apiService.updateProject(
      projectId,
      partialProject,
    );
    if (project === null) {
      return null;
    }
    const projectIndex = this.projects.findIndex(
      (project) => project.id === projectId,
    );
    this.projects[projectIndex] = {
      ...this.projects[projectIndex],
      ...project,
    };
    return this.projects[projectIndex];
  }

  async resetProject(projectId: string) {
    const project = await this.apiService.resetProject(projectId);
    const projectIndex = this.projects.findIndex(
      (project) => project.id === projectId,
    )
    await fileService.deleteNonTemplateFilesFromProject(project);
    this.projects[projectIndex] = project;
    return this.projects[projectIndex];
  }

  async getProjectTemplates() {
    const templates = await this.templateApiService.getProjectTemplates();
    this.templates = templates;
    return templates;
  }

  async getProjectInfo(id: string) {
    return this.apiService.getProjectInfo(id);
  }

  async setProjectInfo(id: string, info: InfoPartOfProject) {
    return this.apiService.setProjectInfo(id, info);
  }

  async likeProject(id: string) {
    return this.apiService.likeProject(id);
  }

  async getShowcaseProjects(year?: number) {
    const projects = await this.apiService.getShowcaseProjects(year);
    const trainings = await this.apiService.getTrainingsForProjects(projects);

    return projects.map((project) => {
      const training = trainings[project.id] || null;
      return { ...project, training };
    });
  }

  setProjects(projects: Project[]) {
    this.projects = projects;
  }

  setToken(token: string) {
    this.apiService.setToken(token);
    this.templateApiService.setToken(token);
  }
}
