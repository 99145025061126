import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import File, { FileStore, FileData } from '@projectTypes/File';
import Store from '@projectTypes/Store';
import fileService from '@services/FileService';
import userActionService from '@services/UserActionService';
import actionNameCreator from '@helpers/actionNameCreator';
import getQueryStringValue from '@helpers/getQueryStringValue';
import config from '@config';

import getOpenFileNames from './helpers/getOpenFileNames';
import { fillPipeline } from './pipelineSlices';
import { fillPolicy } from './policySlices';
import { changeRealTimeFile, fileSave } from './realTimeEditSlices';
import { setIsProjectChanged, setShowProjectInfo } from './projectSlices';
import { getPcbTypeFromFile } from './terminalSlices';

const fileInitialState: FileStore = {
  showFileDiff: false,
  openIds: [],
  files: [],
  displayedFileId: null,
  isLoading: false,
  jupyterState: 0,
};

const anc = actionNameCreator('FILE');

export const setDisplayedFileId = createAsyncThunk<
  Promise<string>,
  string,
  { state: Store }
>(anc('setDisplayedFileId'), async (fileId: string, { dispatch, getState }) => {
  const state = getState();
  dispatch(setShowProjectInfo(false));
  const selectedProject = state.project.projects.find(
    (project) => project.id === state.project.selectedProjectId,
  );
  // TODO: delegate to realTimeEditSlices
  if (selectedProject?.isGroupProject) {
    const displayedFile = state.file.files.find(
      (file) => file.id === state.file.displayedFileId,
    );
    if (displayedFile && displayedFile.isChanged) {
      await dispatch(saveFiles([displayedFile.id]));
    }
    await dispatch(
      getFileData({ projectId: state.project.selectedProjectId, id: fileId }),
    );
    dispatch(changeRealTimeFile(fileId));
  }
  userActionService.trackAction('editor_open_file', {
    projectId: selectedProject.id,
    fileId,
  });
  return fileId;
});

const fileSlice = createSlice({
  name: 'file',
  initialState: fileInitialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    addFile(state, action: PayloadAction<File>) {
      const foundFileIndex = state.files.findIndex(
        (file) => file.id === action.payload.id,
      );
      if (foundFileIndex !== -1) {
        state.files[foundFileIndex] = {
          ...action.payload,
          fileCounter: state.files[foundFileIndex].fileCounter + 1,
        };
        state.files = [...state.files];
      } else {
        state.files.push({ ...action.payload, fileCounter: 1 });
      }
    },
    removeFile(state, action: PayloadAction<string>) {
      const fileIndex = state.files.findIndex(
        (file) => file.id === action.payload,
      );
      if (fileIndex !== -1) {
        state.files.splice(fileIndex, 1);
        state.files = [...state.files];
      }
    },
    increaseFileCounter(state, _action: PayloadAction<void>) {
      const foundFileIndex = state.files.findIndex(
        (file) => file.id === state.displayedFileId,
      );
      if (foundFileIndex !== -1) {
        state.files[foundFileIndex].fileCounter += 1;
        state.files = [...state.files];
      }
    },
    setIsOpen(
      state,
      action: PayloadAction<{ isOpen: boolean; fileId: string }>,
    ) {
      const { fileId, isOpen } = action.payload;
      if (isOpen && !state.openIds.includes(fileId)) {
        state.openIds.push(fileId);
      } else if (!isOpen && state.openIds.includes(fileId)) {
        state.openIds.splice(state.openIds.indexOf(fileId), 1);
      }
    },
    closeAllFiles(state, _action: PayloadAction<void>) {
      state.openIds = [];
      state.displayedFileId = null;
    },
    setNewFileContent(
      state,
      action: PayloadAction<{ fileId: string; newContent: string; blob?: any }>,
    ) {
      const { fileId, newContent, blob } = action.payload;
      fileService.setNewFileContent(fileId, newContent, blob);
      state.files = state.files.map((file) => {
        if (file.id === action.payload.fileId) {
          return {
            ...file,
            isChanged: file.content.trim() !== action.payload.newContent.trim(),
            newContent: action.payload.newContent,
          };
        }
        return file;
      });
    },
    setShowFileDiff(state, action: PayloadAction<boolean>) {
      state.showFileDiff = action.payload;
    },
    setInitialFiles(state, _action: PayloadAction<void>) {
      fileService.setFiles(JSON.parse(JSON.stringify(state.files)));
    },
    removeProjectFiles(state, action: PayloadAction<string>) {
      state.files = [
        ...state.files.filter((file) => file.projectId !== action.payload),
      ];
    },
    setFileHasError(
      state,
      action: PayloadAction<{ hasError: boolean; fileId: string }>,
    ) {
      const file = state.files.find((f) => f.id === action.payload.fileId);
      if (file) {
        file.hasError = action.payload.hasError;
        state.files = [...state.files];
      }
    },
    setJupyterState(state, action: PayloadAction<number>) {
      state.jupyterState = action.payload;
    },
  },
  extraReducers: {
    [setDisplayedFileId.fulfilled as any]: (state, action) => {
      state.displayedFileId = action.payload;
    },
  },
});

export const {
  addFile,
  setIsOpen,
  closeAllFiles,
  setNewFileContent,
  setIsLoading,
  setShowFileDiff,
  setInitialFiles,
  increaseFileCounter,
  removeFile,
  removeProjectFiles,
  setFileHasError,
  setJupyterState,
} = fileSlice.actions;
export default fileSlice.reducer;

export const createNewFile = createAsyncThunk(
  anc('createNewFile'),
  async (fileData: FileData, { dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const file = await fileService.createFile(fileData);
      if (file) {
        dispatch(addFile(file));
      }
      dispatch(setIsLoading(false));
    } catch (e) {
      dispatch(setIsLoading(false));
      throw e;
    }
  },
);

export const getFileData = createAsyncThunk(
  anc('getFileData'),
  async (
    { projectId, id }: { projectId: string; id: string },
    { dispatch },
  ) => {
    dispatch(setIsLoading(true));
    const file = await fileService.getFile(projectId, id);
    if (file) {
      dispatch(addFile(file));
    }
    dispatch(setIsLoading(false));
  },
);

const getFileByPath = (files: File[], path: string) => {
  return files.find((file) => `${file.path || '/'}${file.name}` === path);
};

export const getProjectFiles = createAsyncThunk(
  anc('getProjectFiles'),
  async (projectId: string, { dispatch }) => {
    dispatch(setIsLoading(true));
    dispatch(removeProjectFiles(projectId));
    const studentProjectId = getQueryStringValue(config.studentProjectId);
    const files = await fileService.getProjectFiles(
      projectId,
      typeof studentProjectId === 'string',
    );
    const pipelineFile = getFileByPath(files, '/pipeline.yml');
    const policiesFile = getFileByPath(files, '/policies.yml');
    const mainInoFile = getFileByPath(files, '/main.ino');
    const mainXmlFile = getFileByPath(files, '/main.xml');

    if (pipelineFile) dispatch(fillPipeline(pipelineFile));
    if (policiesFile) dispatch(fillPolicy(policiesFile));
    if (mainInoFile) {
      dispatch(getPcbTypeFromFile({ file: mainInoFile }));
    }

    const openFileNames = getOpenFileNames();
    if (files) {
      files.forEach((file: File) => {
        dispatch(addFile(file));
        if (openFileNames.includes(file.name)) {
          dispatch(setIsOpen({ isOpen: true, fileId: file.id }));
          dispatch(setDisplayedFileId(file.id));
        }
      });
    }
    if (!openFileNames || openFileNames.length === 0) {
      if (files) {
        // TODO: have a list of hidden files
        const editableFiles = files.filter(
          (f) => !f.static && !f.hidden && f.name !== 'main.xml',
        );
        if (editableFiles.length === 1) {
          dispatch(setIsOpen({ isOpen: true, fileId: editableFiles[0].id }));
          dispatch(setDisplayedFileId(editableFiles[0].id));
        }
      }
    }
    dispatch(setIsLoading(false));
  },
);

export const saveProjectFiles = createAsyncThunk(
  anc('saveProjectFiles'),
  async (projectId: string, { dispatch }) => {
    dispatch(setIsLoading(true));
    const projectFiles = await fileService.saveProjectFiles(projectId);
    if (projectFiles) {
      projectFiles.forEach((file) => {
        dispatch(addFile(file));
        dispatch(fileSave(file));
      });
    }
    dispatch(setIsProjectChanged(false));
    dispatch(setIsLoading(false));
    userActionService.trackAction('editor_save_project', {
      projectId,
    });
  },
);

export const saveFiles = createAsyncThunk(
  anc('saveFiles'),
  async (fileIds: string[], { dispatch }) => {
    dispatch(setIsLoading(true));
    const projectFiles = await fileService.saveFiles(fileIds);
    if (projectFiles) {
      projectFiles.forEach((file) => {
        dispatch(addFile(file));
        dispatch(fileSave(file));
        userActionService.trackAction('editor_save_file', {
          projectId: file.projectId,
          fileId: file.id,
        });
      });
    }
    dispatch(setIsLoading(false));
  },
);

export const getProjectTemplateFiles = createAsyncThunk(
  anc('getProjectTemplateFiles'),
  async (templateId: string, { dispatch }) => {
    dispatch(setIsLoading(true));
    const files = await fileService.getProjectTemplateFiles(templateId);
    if (files) {
      files.forEach((file) => {
        dispatch(addFile(file));
      });
    }
    dispatch(setIsLoading(false));
  },
);

export const resetFile = createAsyncThunk(
  anc('resetFile'),
  async (fileId: string, { dispatch }) => {
    dispatch(setShowFileDiff(false));
    dispatch(setIsLoading(true));
    const file = await fileService.resetFile(fileId);
    dispatch(addFile(file));
    dispatch(setIsLoading(false));
    userActionService.trackAction('editor_reset_file', { fileId });
  },
);

export const deleteFile = createAsyncThunk(
  anc('deleteFile'),
  async (fileId: string, { dispatch }) => {
    dispatch(setIsLoading(true));
    await fileService.deleteFile(fileId);
    dispatch(setIsOpen({ fileId, isOpen: false }));
    dispatch(removeFile(fileId));
    dispatch(setIsLoading(false));
  },
);

export const deleteFolder = createAsyncThunk(
  anc('deleteFolder'),
  async (fileId: string, { dispatch }) => {
    dispatch(setIsLoading(true));
    let deletePath;
    const files = await fileService.getFiles();

    deletePath = files.find((el) => {
      return el.id === fileId;
    });

    files.forEach((el) => {
      let curPath = el?.path;
      if (curPath.match(deletePath)) {
        dispatch(deleteFile(el.id));
      }
    });

    dispatch(setIsLoading(false));
  },
);

export const setNewFileName = createAsyncThunk(
  anc('setNewFileName'),
  async (
    {
      fileId,
      name,
      ext,
    }: {
      fileId: string;
      name: string;
      ext?: string;
    },
    { dispatch },
  ) => {
    dispatch(setIsLoading(true));
    const file = await fileService.setNewFileName(fileId, name, ext);
    if (file) {
      dispatch(addFile(file));
    }
    dispatch(setIsLoading(false));
  },
);
